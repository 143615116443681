import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import StartSection from 'components/StartSection';
import useWindowSize from 'hooks/useWindowSize';
import useToggle from 'hooks/useToggle';
import useIsMobile from 'hooks/useIsMobile';
import headerVideo from 'videos/miningcity_video_new.mp4';
import styled, { theme } from 'styles/styled';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import EducationNavigation from 'components/EducationNavigation/EducationNavigation';
import {
  Container,
  HeaderVideo,
  StartSectionWrapper,
  HeaderBackgroundImage,
  HeaderWrapper,
} from './styled';

const TrustedStamp = styled.img`
  display: none;
  position: absolute;
  left: 24px;
  z-index: 100;
  bottom: 24px;
  height: 200px;
  width: 200px;

  @media ${theme.devices.medium} {
    display: block;
  }
`;

const Header = () => {
  const { imageSharp } = useStaticQuery(graphql`
    query HeaderBackground {
      imageSharp(
        fluid: { originalName: { in: "mining-city-background-image.jpg" } }
      ) {
        id
        fixed(base64Width: 800) {
          base64
        }
      }
    }
  `);
  const isMobile = useIsMobile();

  const { on: isMenuOpen, setOff: closeMenu } = useToggle();
  const { width } = useWindowSize();

  useBodyScrollLock(isMenuOpen);

  useEffect(() => {
    if (width >= theme.deviceSize.large) {
      closeMenu();
    }
  }, [width, closeMenu]);

  return (
    <Container>
      <EducationNavigation />
      <HeaderWrapper>
        {!isMobile && (
          <TrustedStamp
            src="/MC-trusted-stamp.svg"
            alt="Mining City trusted stamp"
          />
        )}
        {!isMobile && (
          <HeaderVideo muted loop autoPlay playsInline>
            <source src={headerVideo} type="video/mp4" />
          </HeaderVideo>
        )}
        {isMobile && (
          <HeaderBackgroundImage
            src={imageSharp.fixed.base64}
            alt="Mining City facilities"
          />
        )}
        <StartSectionWrapper>
          {!isMobile && <StartSection />}
        </StartSectionWrapper>
      </HeaderWrapper>
      <Triangle
        height="210px"
        width="40%"
        color={theme.palette.primary}
        position={{
          bottom: 0,
          right: 0,
        }}
        cornerType={CornerTypes['bottom-right']}
      />
      <Triangle
        height="50px"
        width="100%"
        color={theme.palette.light}
        showOnMobile
        position={{
          bottom: '-1px',
          left: 0,
        }}
        cornerType={CornerTypes['bottom-left']}
      />
    </Container>
  );
};

export default Header;
