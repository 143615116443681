import React from 'react';
import { graphql } from 'gatsby';
import loadableVisibility from 'react-loadable-visibility/loadable-components';

import styled from 'styles/styled';
import menuLinks from 'settings/menuLinks';
import Layout from 'components/Layout';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import AboutSection from 'components/AboutSection/AboutSection';
import Steps from 'components/Steps';
import StartSection from 'components/StartSection';
import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
} from 'settings/i18n';
import ClientOnly from 'components/ClientOnly/ClientOnly';

const EventsSection = loadableVisibility(
  () => import(`components/EventsSection`),
);
const FullContactUs = loadableVisibility(
  () => import('components/ContactUs/FullContactUs'),
);
const ContactUs = loadableVisibility(() => import('components/ContactUs'));

const StartSectionWrapper = styled.div`
  display: block;
  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

const Main = ({ data }: { data: TranslationResult & SEOMeta }) => {
  const combinedTranslations = {
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.about?.translation,
        ...data?.homepage?.translation,
      },
    },
  };

  const t = translationsFactory(combinedTranslations);

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata || undefined} url={menuLinks.home} />
      <ClientOnly>
        <Layout>
          <StartSectionWrapper>
            <StartSection />
          </StartSectionWrapper>
          <section id="about-us">
            <AboutSection />
          </section>
          <section id="how-to">
            <Steps />
          </section>
          <section id="events">
            <EventsSection data={data.eventsData} />
          </section>
          {process.env.SHOW_FULL_CONTACT_FORM === 'y' ? (
            <FullContactUs />
          ) : (
            <ContactUs />
          )}
        </Layout>
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default Main;

export const query = graphql`
  query($locale: String!) {
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }
    about: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...AboutTranslation
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...ContactTranslation
      ...StepsTranslation
      ...StartTranslation
      ...HeaderTranslation
      ...FooterTranslation
      ...EventsTranslation
      ...AboutTranslation
    }
    eventsData: allWpPost(filter: {
     language: { locale: { eq: $locale } }
     categories: { nodes: { elemMatch: { slug: { regex: "/events/" }}}}
    }) {
      nodes {
        acfFieldGroup {
          date
          endDate
          eventName
          location
          firstImage {
            localFile {
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          secondImage {
            localFile {
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
  }
`;
